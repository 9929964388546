.backdrop {
  background: hsla(0, 0%, 0%, 0.5);
  z-index: 1050;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  backdrop-filter: blur(5px);
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.3s;

  &.clear {
    background: none;
    backdrop-filter: unset; }

  &.open {
    opacity: 1;
    visibility: visible; } }
