
.push-left\:hover {
  transition: all 0.5s ease-in-out;

  &:hover {
    transform: translateX(-0.25rem); } }

.push-right\:hover {
  transition: transform 0.5s ease-in-out;

  &:hover {
    transform: translateX(0.25rem); } }

.grow\:hover {
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.2); } }


.fade\:hover {
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.75; } }


.shake {
  animation: shake 0.2s ease-in-out 0s 2; }


@keyframes shake {
  25% {
    transform: translateX(1rem); }

  75% {
    transform: translateX(-1rem); } }


.from-right {
  animation: from-right 0.5s 0s ease-in-out; }


.to-right {
  animation: to-right 0.5s 0s ease-in-out;
  opacity: 0; }


.from-left {
  animation: from-left 0.5s 0s ease-in-out; }


.to-left {
  animation: to-left 0.5s 0s ease-in-out;
  opacity: 0; }


@keyframes from-right {
  from {
    opacity: 0;
    transform: translateX(1.5rem); }


  to {
    opacity: 1;
    transform: translateX(0); } }


@keyframes to-right {
  to {
    opacity: 0;
    transform: translateX(1.5rem); }


  from {
    opacity: 1; } }
/*     transform: translateX(0) */



@keyframes from-left {
  from {
    opacity: 0;
    transform: translateX(-1.5rem); }


  to {
    opacity: 1;
    transform: translateX(0); } }


@keyframes to-left {
  to {
    opacity: 0;
    transform: translateX(-1.5rem); }


  from {
    opacity: 1;
    transform: translateX(0); } }
