.skeleton {
    display: inline-flex;
    flex-direction: column;
    vertical-align: middle;
    width: 100%;
}
.skeleton > .skeleton-item {
    background: linear-gradient(90deg, #dbdbdb 25%, rgba(219, 219, 219, 0.5) 50%, #dbdbdb 75%);
    background-size: 400% 100%;
    width: 100%;
}
.skeleton > .skeleton-item.is-rounded {
    border-radius: 4px;
}
.skeleton > .skeleton-item::after {
    content: "\00a0";
}
.skeleton > .skeleton-item + .skeleton-item {
    margin-top: 0.5rem;
}
.skeleton.is-animated > .skeleton-item {
    animation: skeleton-loading 1.5s infinite;
}
.skeleton.is-centered {
    align-items: center;
}
.skeleton.is-right {
    align-items: flex-end;
}
.skeleton + .skeleton {
    margin-top: 0.5rem;
}
.skeleton > .skeleton-item {
    line-height: 1rem;
}
.skeleton.is-small > .skeleton-item {
    line-height: 0.75rem;
}
.skeleton.is-medium > .skeleton-item {
    line-height: 1.25rem;
}
.skeleton.is-large > .skeleton-item {
    line-height: 1.5rem;
}
@keyframes skeleton-loading {
    0% {
        background-position: 100% 50%;
   }
    100% {
        background-position: 0 50%;
   }
}
