
.hide {
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  display: none !important; }



.flex-0 {
  flex: 1 0 0%; }
