@tailwind base {}
@tailwind components {}
@tailwind utilities {}

// App Customs
@import 'generic';
@import 'animations';
@import 'misc';
@import 'utilities';
@import 'powerbi';

