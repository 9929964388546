body,
html {
  font-family: 'Inter var', "Poppins", "Helvetica Neue", "Roboto", "Cambria", helvetica, arial, sans-serif; }

// Tailwind overrides
//button:focus,
//a:focus
//  @apply ring-indigo-500 ring-offset-2 ring-2 outline-none ring-offset-transparent

a {
  @apply text-blue-700 font-medium {} }


td {
  @apply md:py-3 px-2 align-middle text-left {} }

th {
  @apply md:py-3 px-2 align-middle text-left {} }

thead tr {
  @apply border-b {} }

tbody > tr {
  @apply last:border-b-0 border-b hover:bg-gray-200/50 {} }


tr {
  @apply py-2 md:py-0 w-full {} }
